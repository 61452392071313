<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Buscar</v-toolbar-title>
        <v-divider class="mx-4" inset vertical> </v-divider>
        <v-spacer></v-spacer>

        <v-text-field v-model="searchCodCliente" append-icon="mdi-magnify" label="Codigo cliente" single-line
          class="mt-3 mx-4" @keydown.enter="cargarLista(true)" @click:append="cargarLista(true)"></v-text-field>

        <v-text-field v-model="searchCliente" append-icon="mdi-magnify" label="Cliente" single-line class="mt-3 mx-4"
          @keydown.enter="cargarLista(true)" @click:append="cargarLista(true)"></v-text-field>

        <date-picker v-model="searchFecha" valueType="YYYY/MM/DD HH:MM" time-title-format="YYYY/MM/DD HH:MM"
          :show-second="false" @change="cargarLista(true)" :range="true" type="datetime" placeholder="Fecha"
          :confirm="true" confirm-text="Seleccionar" :show-time-panel="false">
          <template v-slot:footer="{ emit }">
            <button class="mx-btn" @click="showTimeRangePanel = !showTimeRangePanel">
              {{ showTimeRangePanel ? 'Fecha' : 'Tiempo' }}
            </button>
            <button class="mx-btn" @click="selectToDay(emit)">
              Hoy
            </button>
          </template>
        </date-picker>

        <v-text-field type="date" v-model="searchFecha" append-icon="mdi-magnify" label="Fecha" single-line
          class="mt-3 mx-4" @keydown.enter="cargarLista(true)" @click:append="cargarLista(true)"></v-text-field>
      </v-toolbar>

      <v-toolbar flat color="white">
        <!-- <v-btn color="primary" small class="ma-2 white--text" @click="exportar()">
          <v-icon left>mdi-microsoft-excel</v-icon>
          Exportar
        </v-btn> -->
        <v-btn color="blue" small class="ma-2 white--text" @click="solicitarPago">
          <v-icon left>mdi-cash-refund</v-icon>
          Solicitar pago
        </v-btn>
      </v-toolbar>

      <v-row>
        <v-col md="12" sm="12">
          <v-data-table :headers="headers" :items="lista" :items-per-page="itemsPerPage" :loading="loadingTable"
            :hide-default-footer="true" class="elevation-1" show-select v-model="pagosSelec" item-key="payment_id">
            <template v-slot:item.opcion="{ item }">
              <v-btn icon target="_blank" :to="`/ver-pago/${item.payment_id}`">
                <v-icon color="green">mdi-eye</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.cliente="{ item }">
              {{ item.cliente }}
              <v-btn text target="_blank" :to="`/clientes/${item.party_id}`">
                <v-icon color="green">mdi-eye</v-icon>
                {{ item.party_id }}
              </v-btn>
            </template>

            <template v-slot:footer>
              <v-pagination class="mt-10" v-model="currentPage" :length="pageCount" @input="handlePageChange"
                total-visible="15"></v-pagination>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- DIALOGO PAGO DE SERVICIO-->
    <v-dialog v-model="dialogPagoServicio" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Pagar a</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formPagoServicio">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <v-autocomplete v-model="selProveedor" :items="proveedores" item-value="party_id"
                    item-text="party_nombres" dense label="Seleccione un proveedor">
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title v-html="item.party_nombres"></v-list-item-title>
                        <!-- <v-list-item-subtitle
                          v-html="`${item.saldo} - ${item.due_date}`"
                        ></v-list-item-subtitle> -->
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-text-field label="Monto total" v-model="montoSolicitado"></v-text-field>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0"> </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="blue darken-1" text @click="dialogPagoServicio = false">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" solid small class="white--text" @click="enviarSolicitudPagoServicio">
            <v-icon>mdi-check</v-icon>
            Solicitar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: "ReembolsosComponent",
  components: {
    DatePicker
  },
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion", align: "center", width: 40 },
      { text: "Numero", value: "payment_id" },
      { text: "Fecha", value: "effective_date" },
      { text: "Tipo", value: "tipo" },
      { text: "Cliente", value: "cliente" },
      { text: "Total", value: "total", align: "right" },
      { text: "Pagado", value: "pagado", align: "right" },
      { text: "Saldo", value: "saldo", align: "right" },
    ],
    lista: [],
    options: {},
    dialog: false,
    notifications: false,
    sound: true,
    widgets: true,
    showTimeRangePanel: false,
    showTimePanel: false,



    itemsEstado: [
      { estadoId: "INVOICE_READY", estadoNombre: "Listo" },
      { estadoId: "INVOICE_PAID", estadoNombre: "Pagado" },
      { estadoId: "INVOICE_IN_PROCESS", estadoNombre: "En proceso" },
    ],
    proveedores: [],
    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 50,

    searchNumber: "",
    searchFechaInv: "",
    searchEstado: "",
    searchCliente: "",
    searchCodCliente: "",
    searchFecha: [],
    pagosSelec: [],
    dialogPagoServicio: false,
    selProveedor: "",
    montoSolicitado: 0,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId"]),

    ...mapGetters("access", ["btnAbrirCaja"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista(filtrar) {
      if (filtrar == true) {
        this.currentPage = 1;
      }
      this.setLoadingTable(true);

      this.setUrl("valores-anticipados-clientes");
      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          page_count: this.pageCount,
          page_size: this.itemsPerPage,
          cliente: this.searchCliente,
          party_id: this.searchCodCliente,
          fecha: this.searchFecha
        },
      })
        .then((res) => {
          console.log(res.data);
          this.lista = res.data.detail.valores_anticipados_clientes;
          this.pageCount = res.data.detail.page_count;
          this.proveedores = res.data.detail.provs;
          this.setLoadingTable(false);
        })
        .then(() => { });
    },

    enviarSolicitudPagoServicio() {
      let paySel = [];
      this.montoSolicitado = 0;
      for (let i = 0; i < this.pagosSelec.length; i++) {
        paySel.push({
          payment_id: this.pagosSelec[i].payment_id,
          valor: this.pagosSelec[i].saldo,
        });
        this.montoSolicitado += parseFloat(this.pagosSelec[i].saldo);
      }
      if (paySel.length == 0) {
        alert("Seleccione al menos un pago");
        return false;
      }
      console.log(paySel);
      Vue.swal({
        html: "Está seguro de solicitar el pago al proveedor del servicio ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crear-doc-por-pagar");
          this.requestApi({
            method: "POST",
            data: {
              proveedor_id: this.selProveedor,
              payments: paySel,
              monto: this.montoSolicitado,
            },
          }).then((res) => {
            //this.cargarPago();
            this.cargarLista();
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
            this.dialogPagoServicio = false;
            this.pagosSelec = [];
          });
        }
      });
    },

    solicitarPago() {
      let paySel = [];
      this.montoSolicitado = 0;
      for (let i = 0; i < this.pagosSelec.length; i++) {
        paySel.push(this.pagosSelec[i].payment_id);
        this.montoSolicitado += parseFloat(this.pagosSelec[i].saldo);
      }
      this.dialogPagoServicio = true;
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.cargarLista(false);
    },
  },

  mounted() {
    this.setTitleToolbar("VALORES ANTICIPADOS");
    this.cargarLista(false);
  },

  watch: {
    searchNumber: {
      handler() {
        this.cargarLista(true);
      },
    },
    searchEstado: {
      handler() {
        this.cargarLista(true);
      },
    },
  },
};
</script>
